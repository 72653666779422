var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('main-visual', {
    staticClass: "d-none d-md-block",
    attrs: {
      "code": "main-banners-pc"
    }
  }), _c('main-visual', {
    staticClass: "d-md-none",
    attrs: {
      "code": "main-banners-mobile"
    }
  }), _c('v-container', [_c('v-card', {
    staticClass: "h-lg-200px mt-40 mt-sm-n40 mt-md-40 mt-lg-n100 d-flex align-center",
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "rounded": "xl"
    }
  }, [_c('div', {
    staticClass: "w-100 px-20 py-30 px-lg-74 py-lg-36"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-10"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("간편메뉴")])]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v("클릭으로 빠르게 원하시는 정보를 찾아보세요.")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "",
      "lg": "auto"
    }
  }, [_c('v-row', {
    staticClass: "ma-n8 ma-md-n12",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-8 pa-md-12 text-center",
    attrs: {
      "cols": "4",
      "lg": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/program/items"
    }
  }, [_c('v-responsive', {
    staticClass: "ma-auto",
    attrs: {
      "width": "100px",
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary lighten-5"
    }
  }, [_c('i', {
    staticClass: "icon icon-calendar"
  })])], 1), _c('p', {
    staticClass: "page-text font-size-16 mt-8 word-keep-all"
  }, [_vm._v("운영 프로그램")])], 1)], 1), _c('v-col', {
    staticClass: "pa-8 pa-md-12 text-center",
    attrs: {
      "cols": "4",
      "lg": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/rental/spaces"
    }
  }, [_c('v-responsive', {
    staticClass: "ma-auto",
    attrs: {
      "width": "100px",
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary lighten-5"
    }
  }, [_c('i', {
    staticClass: "icon icon-place"
  })])], 1), _c('p', {
    staticClass: "page-text font-size-16 mt-8"
  }, [_vm._v("공간대관")])], 1)], 1), _c('v-col', {
    staticClass: "pa-8 pa-md-12 text-center",
    attrs: {
      "cols": "4",
      "lg": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/intro/location"
    }
  }, [_c('v-responsive', {
    staticClass: "ma-auto",
    attrs: {
      "width": "100px",
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary lighten-5"
    }
  }, [_c('i', {
    staticClass: "icon icon-car"
  })])], 1), _c('p', {
    staticClass: "page-text font-size-16 mt-8"
  }, [_vm._v("오시는길")])], 1)], 1)], 1)], 1)], 1)], 1)])], 1), _c('main-programs'), _c('page-section', {
    staticClass: "page-section--py page-section--bg",
    staticStyle: {
      "background-image": "url(/images/main/hura-bg.png)",
      "background-position": "center bottom"
    }
  }, [_c('v-row', {
    staticClass: "main-board my-n20 mx-md-n20 mx-lg-n40"
  }, [_c('main-notices'), _c('main-presses')], 1)], 1), _c('page-section', {
    staticClass: "page-section--py"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', [_c('div', {
    staticClass: "pa-20 pa-md-30"
  }, [_c('program-schedule-calendar', _vm._b({
    attrs: {
      "no-title": ""
    }
  }, 'program-schedule-calendar', {
    programs: _vm.programs
  }, false))], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('program-schedule-list', _vm._b({
    attrs: {
      "moreBtn": ""
    }
  }, 'program-schedule-list', {
    programs: _vm.programs
  }, false))], 1)], 1)], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }