var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--py"
  }, [_c('div', {
    staticClass: "main-banner"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v("센터운영 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("프로그램")])]), _c('v-btn', {
    staticClass: "v-size--xx-small",
    attrs: {
      "rounded": "",
      "color": "primary lighten-5",
      "to": "/program/items"
    }
  }, [_c('i', {
    staticClass: "icon icon-plus-sm mr-4"
  }), _vm._v("더보기")]), _c('div', {
    staticClass: "mt-40 mt-md-60"
  }, [_c('div', {
    staticClass: "swiper-control"
  }, [_c('div', {
    staticClass: "swiper-prev"
  }, [_c('i', {
    staticClass: "icon icon-chevron-left"
  })]), _c('div', {
    staticClass: "swiper-next"
  }, [_c('i', {
    staticClass: "icon icon-chevron-right"
  })])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('main-program-banners', _vm._b({}, 'main-program-banners', {
    programs: _vm.programs
  }, false))], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }