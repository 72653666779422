var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.className == 'sub-tab-wrap' ? _c('div', {
    class: _vm.className
  }, [_c('v-container', [_vm._l(_vm.gnbs, function (item) {
    return [item.title == _vm.tabTitle ? _c('ul', {
      key: item.title,
      staticClass: "tab justify-center"
    }, _vm._l(item.children, function (child) {
      return _c('li', {
        key: child.title,
        staticClass: "tab__li",
        class: {
          active: _vm.tabActive == child.title
        }
      }, [_c('router-link', {
        staticClass: "tab__btn",
        attrs: {
          "to": child.path
        }
      }, [_c('span', [_vm._v(_vm._s(child.title))])])], 1);
    }), 0) : _vm._e()];
  })], 2)], 1) : _vm.className == 'gnb' ? _c('ul', {
    class: _vm.className
  }, [_vm._l(_vm.gnbs, function (item) {
    return [!item.children ? _c('li', {
      key: item === null || item === void 0 ? void 0 : item.title,
      class: _vm.className + '__li'
    }, [_c('router-link', {
      class: _vm.className + '__link',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])])], 1) : _c('li', {
      key: item === null || item === void 0 ? void 0 : item.title,
      class: _vm.className + '__li'
    }, [_c('div', {
      class: _vm.className + '__link gnb__link--toggle d-xl-none'
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), _c('router-link', {
      class: _vm.className + '__link gnb__link--toggle d-none d-xl-flex',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), _c('ul', {
      class: _vm.className + '__sub'
    }, _vm._l(item.children, function (child) {
      return _c('li', {
        key: child === null || child === void 0 ? void 0 : child.title
      }, [_c('router-link', {
        attrs: {
          "to": child.path
        }
      }, [_vm._v(_vm._s(child.title))])], 1);
    }), 0)], 1)];
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }