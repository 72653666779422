import axios from "@/plugins/axios";

let url = (data) => "/api/v1/me/programs/${_program}/applications".interpolate(data);

export default {
    gets(data) {
        return axios.get(url(data), data).then((result) => result.data);
    },
    getCurrent(data) {
        return axios.get(`${url(data)}/current`).then((result) => result.data);
    },
    post(data) {
        return axios.post(url(data), data).then((result) => result.data);
    },
    putCancel(data) {
        return axios.put(`${url(data)}/${data._id}/cancel`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url(data)}/${data._id}`).then((result) => result.data);
    },
};
