<template>
    <div class="pt-40 pt-md-0 pl-md-20 pl-lg-40 pl-xl-60 pt-lg-50">
        <v-row align="center" justify="space-between" class="row--small">
            <v-col cols="auto">
                <div class="tit-wrap--dot mb-0"><h2 class="tit">행사일정</h2></div>
            </v-col>
            <v-col cols="auto">
                <span class="font-size-14 font-size-md-16 font-size-lg-18 grey-4e--text">{{ date }}</span>
            </v-col>
            <v-spacer />
            <v-col v-if="moreBtn" cols="auto">
                <v-btn rounded color="primary lighten-5" to="/program/schedule" class="v-size--xx-small"><i class="icon icon-plus-sm mr-4"></i>더보기</v-btn>
            </v-col>
        </v-row>
        <v-divider class="my-14 my-md-24" />

        <v-row class="row--x-small">
            <v-col v-if="dateItems.length < 1" cols="12">
                <div class="font-size-18 font-size-md-20">진행중인 행사 일정이 없습니다.</div>
            </v-col>
            <v-col v-for="item in dateItems" :key="`${item._id} - ${item.state}`" cols="12">
                <router-link :to="`/program/items/${item._id}`">
                    <v-row class="row--small font-size-18 font-size-md-20 cursor-pointer">
                        <v-col cols="auto"><span class="primary--text">•</span></v-col>
                        <v-col>
                            <span class="primary--text pr-14 pr-md-18">{{ item?.state }}</span>
                            <span>[{{ item?.category?.name }}] {{ item?.name }}</span>
                        </v-col>
                    </v-row>
                </router-link>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        moreBtn: { type: Boolean, default: false },
        programs: { type: Array, default: () => [] },
    },
    computed: {
        date() {
            return this.$route.query.date;
        },
        dateItems() {
            return this.programs.reduce((items, item) => {
                let onApplication = !dayjs(this.date).isBefore(item.applicationStartsAt, "date") && !dayjs(this.date).isAfter(item.applicationEndsAt, "date");
                if (onApplication) items.push({ ...item, state: "모집중" });

                let onGoing = (item?.units || []).some(({ date }) => dayjs(this.date).isSame(date, "date"));
                if (onGoing) items.push({ ...item, state: "진행중" });

                return items;
            }, []);
        },
    },
};
</script>

<style></style>
