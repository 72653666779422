var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('v-container', {
    staticClass: "py-20 py-lg-24"
  }, [_c('ul', {
    staticClass: "footer-link"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v("회원가입시 약관 및 개인정보취급방침")])], 1)])]), _c('v-divider', {
    staticStyle: {
      "border-color": "#c6e5f3"
    }
  }), _c('v-container', {
    staticClass: "py-20 py-lg-40"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "188",
      "src": "/images/logo.svg"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "pl-lg-16"
  }, [_c('ul', {
    staticClass: "footer-info"
  }, [_c('li', [_vm._v("하남시 도시재생지원센터")]), _c('li', {
    staticClass: "clear"
  }, [_c('span', [_vm._v("주소")]), _vm._v("(12951)경기도 하남시 대청로 8")]), _c('li', {
    staticClass: "clear d-flex"
  }, [_c('span', [_vm._v("대표전화")]), _c('span', {
    staticClass: "d-flex flex-column"
  }, [_c('span', [_vm._v("잇다 : 031 790 6353")]), _c('span', [_vm._v("날다 : 031 796 2173")]), _c('span', [_vm._v("하다 : 031 791 5002")])])]), _c('li', [_c('span', [_vm._v("메일")]), _vm._v("hanamitda@naver.com")])])])]), _c('v-col', {
    staticClass: "d-lg-none",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://instagram.com/hanamhada",
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "icon icon-insta"
  })])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://cafe.naver.com/hanamregen",
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "icon icon-cafe"
  })])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }