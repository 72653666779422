<template>
    <v-date-picker v-model="date" v-bind="{ ...$attrs, events, dayFormat }" full-width color="primary" @input="changeDate" @update:picker-date="changeMonth" class="v-date-picker--default" />
</template>

<script>
import dayjs from "dayjs";

export default {
    props: {
        programs: { type: Array, default: () => [] },
    },
    data: () => ({
        date: dayjs().format("YYYY-MM-DD"),
    }),
    mounted() {
        this.date = this.$route.query.date || dayjs().format("YYYY-MM-DD");
    },
    methods: {
        changeDate(date) {
            let query = { date };
            if (date == dayjs().format("YYYY-MM-DD")) delete query.date;
            this.$router.push({ query });
        },

        changeMonth(date) {
            let month = date.split("-")[1];
            let today = dayjs().format("YYYY-MM-DD");
            if (month != (this.$route.query.date || today).split("-")[1]) {
                if (month == today.split("-")[1]) this.date = today;
                else this.date = dayjs(date).startOf("month").format("YYYY-MM-DD");
                if (this.date == today) this.$router.push({ query: {} });
                else this.$router.push({ query: { date: this.date } });
            }
        },

        dayFormat(date) {
            return dayjs(date).get("date");
        },

        events(date) {
            return this.programs.some((item) => {
                let onApplication = !dayjs(date).isBefore(item.applicationStartsAt, "date") && !dayjs(date).isAfter(item.applicationEndsAt, "date");
                if (onApplication) return true;
                return (item.units || []).some((unit) => dayjs(date).isSame(unit.date, "date"));
            })
                ? "primary"
                : false;
        },
    },
};
</script>

<style scoped>
</style>
