import axios from "@/plugins/axios";

let url = (data) => "/api/console/rental-spaces/${_space}/photos".interpolate(data);

export default {
    post({ _space, index }, file) {
        var headers = { "Content-Type": "multipart/form-data" };
        var formData = new FormData();
        if (_space) formData.append("_space", _space);
        if (index != undefined) formData.append("index", index);
        formData.append("file", file);
        return axios.post(`${url({ _space })}`, formData, { headers }).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url(data)}/${data._id}`).then((result) => result.data);
    },
};
