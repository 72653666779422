import banners from "./banners";
import boards from "./boards";
import center from "./center";
import forms from "./forms";
import me from "./me";
import popups from "./popups";
import programKeywords from "./program-keywords";
import programs from "./programs";
import rentalLimitations from "./rental-limitations";
import rentalSchedules from "./rental-schedules";
import rentalSpaces from "./rental-spaces";
import setting from "./setting";
import terms from "./terms";
import users from "./users";

export default {
    banners,
    boards,
    center,
    forms,
    me,
    popups,
    programKeywords,
    programs,
    rentalLimitations,
    rentalSchedules,
    rentalSpaces,
    setting,
    terms,
    users,
};
