import axios from "@/plugins/axios";

import banners from "./banners";
import boards from "./boards";
import center from "./center";
import forms from "./forms";
import levels from "./levels";
import messages from "./messages";
import popups from "./popups";
import programCategories from "./program-categories";
import programKeywords from "./program-keywords";
import programs from "./programs";
import rentalSchedules from "./rental-schedules";
import rentalSpaces from "./rental-spaces";
import setting from "./setting";
import statistics from "./statistics";
import terms from "./terms";
import users from "./users";
export default {
    banners,
    boards,
    center,
    forms,
    levels,
    messages,
    popups,
    programCategories,
    programKeywords,
    programs,
    rentalSchedules,
    rentalSpaces,
    setting,
    statistics,
    terms,
    users,
    email: {
        post({ to, subject, html }) {
            return axios.post("/api/console/email", { to, subject, html }).then((result) => result.data);
        },
    },
    message: {
        post({ to, subject, message }) {
            return axios.post("/api/console/message", { to, subject, message }).then((result) => result.data);
        },
    },
};
