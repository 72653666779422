<template>
    <v-app>
        <!-- S: HEADER -->
        <main-header></main-header>
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <slot name="subHead" />
            <slot name="contentsImmersive" />
            <div id="contents">
                <slot />
            </div>
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: FOOTER -->
        <main-footer class="pb-40 pb-md-50 pb-lg-0"></main-footer>
        <!-- E: FOOTER -->

        <!-- S: Quick Menu -->
        <v-card class="quick-menu">
            <ul>
                <li>
                    <a class="quick-menu__link" target="_blank" href="https://instagram.com/hanamhada">
                        <div class="quick-menu__icon icon-insta"></div>
                        <div class="quick-menu__tit">하다 인스타</div>
                    </a>
                </li>
                <li>
                    <a class="quick-menu__link" target="_blank" href="https://cafe.naver.com/hanamregen">
                        <div class="quick-menu__icon icon-cafe"></div>
                        <div class="quick-menu__tit">카페</div>
                    </a>
                </li>
                <li v-if="user">
                    <v-dialog-default v-model="dialog" title="내 카드">
                        <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" class="quick-menu__link">
                                <div class="quick-menu__icon icon-qr"></div>
                                <div class="quick-menu__tit">내카드</div>
                            </a>
                        </template>

                        <div class="pa-20 py-md-30 pt-lg-54 pb-lg-34 px-lg-64">
                            <div class="img-wrap">
                                <qrcode-vue :value="user?.number" :size="178" class="d-flex justify-center pa-4" />
                            </div>
                            <v-divider class="border-2 primary lighten-4 my-20 my-md-40" />

                            <v-img max-width="144" src="/images/logo.svg" />
                            <div class="font-size-16 font-size-md-20 mt-10 mt-md-14">
                                <v-row no-gutters>
                                    <v-col cols="auto" sm="3"><b class="pr-10">이름</b></v-col>
                                    <v-col>{{ user.name }}</v-col>
                                </v-row>
                                <v-row no-gutters class="mt-6">
                                    <v-col cols="auto" sm="3"><b class="pr-10">회원번호</b></v-col>
                                    <v-col>{{ user.number }}</v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-dialog-default>
                </li>
            </ul>
        </v-card>

        <div class="mycard-mobile d-lg-none" v-if="user">
            <div class="mycard-mobile__open" @click="sheet = !sheet">
                <span class="font-size-16 font-size-md-18 font-weight-bold">내 카드 <v-icon color="primary" class="ml-4 mb-2">mdi-chevron-up</v-icon></span>
            </div>

            <v-bottom-sheet v-model="sheet" content-class="rounded-t overflow-hidden">
                <div class="white pa-20 py-md-30">
                    <div class="mycard-mobile__header">
                        <div class="font-size-16 font-size-md-18 font-weight-bold white--text">내 카드</div>
                        <v-btn icon color="white" class="mycard-mobile__close" @click.stop="sheet = !sheet"><v-icon>mdi-close</v-icon></v-btn>
                    </div>

                    <div class="pt-30">
                        <v-row align="center">
                            <v-col cols="12">
                                <div class="img-wrap">
                                    <qrcode-vue :value="user?.number" :size="180" class="d-flex justify-center ma-4" />
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <v-divider class="border-2 primary lighten-4 my-6"/>
                            </v-col>
                            <v-col cols="12">
                                <div class="mb-16">
                                    <v-img max-width="144" src="/images/logo.svg" />
                                </div>
                                <div class="font-size-16 font-size-md-20">
                                    <v-row no-gutters>
                                        <v-col cols="auto" sm="3"><b class="pr-10">이름</b></v-col>
                                        <v-col>{{ user.name }}</v-col>
                                    </v-row>
                                    <v-row no-gutters class="mt-6">
                                        <v-col cols="auto" sm="3"><b class="pr-10">회원번호</b></v-col>
                                        <v-col>{{ user.number }}</v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-bottom-sheet>
        </div>
        <!-- E: Quick Menu -->
    </v-app>
</template>

<script>
import api from "@/api";
import QrcodeVue from "qrcode.vue";
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";
import VDialogDefault from "@/components/client/dumb/v-dialog-default.vue";

export default {
    props: {
        isMain: { type: Boolean, default: true },
    },
    components: {
        QrcodeVue,
        MainHeader,
        MainFooter,
        VDialogDefault,
    },
    data() {
        return {
            dialog: false,
            sheet: false,
            user: null,
        };
    },
    computed: {
        _user() {
            return this.$store.state.payload?._user;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        _user() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this._user) this.user = (await api.v1.me.get())?.user;
        },
    },
    // mounted() {
    //     window.addEventListener("copy", this.preventCopy);
    //     window.addEventListener("contextmenu", this.preventCopy);
    //     window.addEventListener("selectstart", this.preventDefault);
    //     window.addEventListener("dragstart", this.preventDefault);
    // },
    // destroyed() {
    //     window.removeEventListener("copy", this.preventCopy);
    //     window.removeEventListener("contextmenu", this.preventCopy);
    //     window.removeEventListener("selectstart", this.preventDefault);
    //     window.removeEventListener("dragstart", this.preventDefault);
    // },
    // methods: {
    //     preventCopy(event) {
    //         alert("보안 정책에 의해 복사를 허용하지 않습니다.");
    //         event.preventDefault();
    //     },
    //     preventDefault(event) {
    //         event.preventDefault();
    //     },
    // },
};
</script>

<style scoped>
/* quick-menu */
.quick-menu {
    display: none;
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 100;
    border-radius: var(--rounded-lg);
    background-color: var(--v-primary-lighten5);
    box-shadow: 0 0 26px var(--shadow-color);
    overflow: hidden;
}
.quick-menu__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    text-align: center;
    -webkit-transition: all ease-out 0.2s;
    -ms-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;
}
.quick-menu__tit {
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--v-primary-base);
    margin-top: 2px;
}
.quick-menu__link:hover {
    background-color: #fff;
}
.quick-menu__link:hover .quick-menu__tit {
    font-weight: 600;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .quick-menu__tit {
        font-size: 2rem;
    }
}
@media (min-width: 1024px) {
    .quick-menu {
        display: block;
    }
}
@media (min-width: 1200px) {
}

/* mycard-mobile */
.mycard-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    overflow: hidden;
    border-radius: var(--rounded) var(--rounded) 0 0;
    box-shadow: 0 0 12px var(--shadow-color);
    background-color: #fff;
}
.mycard-mobile__open {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    cursor: pointer;
}
.mycard-mobile__header{
    position: relative;
    text-align: center;
    background-color:var(--v-primary-base);
    padding:10px 20px;
    margin:-20px -20px 0;
}
.mycard-mobile__close{
    position: absolute;
    top:50%;
    right:0;
    -webkit-transform: translate(0,-50%); -ms-transform: translate(0,-50%); transform: translate(0,-50%);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .mycard-mobile__open {
        height: 50px;
    }
    .mycard-mobile__header{
        background-color:var(--v-primary-base);
        padding:20px;
        margin:-30px -20px 0;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
