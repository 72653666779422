var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "grey-8f--text",
    attrs: {
      "to": ""
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.navigate.apply(null, arguments);
      }
    }
  }, [_vm.accessToken ? [_c('span', [_vm._v(_vm._s(_vm.$t("common.로그아웃")))])] : [_c('span', [_vm._v(_vm._s(_vm.$t("common.로그인")))])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }