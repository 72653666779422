<template>
    <footer class="footer">
        <v-container class="py-20 py-lg-24">
            <ul class="footer-link">
                <li><router-link to="/privacy-policy">회원가입시 약관 및 개인정보취급방침</router-link></li>
                <!-- <li><router-link to="/service-policy">이용약관</router-link></li> -->
            </ul>
        </v-container>
        <v-divider style="border-color:#c6e5f3;" />
        <v-container class="py-20 py-lg-40">
            <v-row align="center">
                <v-col cols="12" md="auto">
                    <v-img max-width="188" src="/images/logo.svg" />
                </v-col>
                <v-col>
                    <div class="pl-lg-16">
                        <ul class="footer-info">
                            <!-- <li v-for="item in info" :key="item._id">
                                <span>{{ item.term }}</span> {{ item.desc }}
                            </li> -->
                            <li>하남시 도시재생지원센터</li>
                            <li class="clear"><span>주소</span>(12951)경기도 하남시 대청로 8</li>
                            <li class="clear d-flex">
                                <span>대표전화</span>
                                <span class="d-flex flex-column"
                                    ><span>잇다 : 031 790 6353</span>
                                    <span>날다 : 031 796 2173</span>
                                    <span>하다 : 031 791 5002</span></span
                                >
                            </li>
                            <li><span>메일</span>hanamitda@naver.com</li>
                        </ul>
                    </div>
                </v-col>
                <v-col cols="12" sm="auto" class="d-lg-none">
                    <v-row align="center" justify="end" class="row--small">
                        <v-col cols="auto"
                            ><a href="https://instagram.com/hanamhada" target="_blank"><i class="icon icon-insta"></i></a
                        ></v-col>
                        <v-col cols="auto"
                            ><a href="https://cafe.naver.com/hanamregen" target="_blank"><i class="icon icon-cafe"></i></a
                        ></v-col>
                    </v-row>
                </v-col>
                <!-- <v-col cols="12" md="auto" class="d-flex">
                    <u-scroll-top class="mt-auto ml-auto"></u-scroll-top>
                </v-col> -->
            </v-row>
        </v-container>
    </footer>
</template>

<script>
import UScrollTop from "@/components/dumb/u-scroll-top.vue";
import api from "@/api";

export default {
    components: {
        UScrollTop,
    },
    data() {
        return {
            setting: null,
        };
    },
    computed: {
        info() {
            return this.setting?.footer?.info || [];
        },
        sns() {
            return (this.setting?.footer?.sns || []).filter(({ shows }) => shows);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const { setting } = await api.v1.setting.get();
            this.setting = setting;
        },
    },
};
</script>

<style scoped>
.footer {
    color: #4d8cb5;
    background-color: var(--v-primary-lighten4);
    border-top: 1px solid #b4d9eb;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}

.footer-link {
    display: flex;
    flex-wrap: wrap;
    margin: -4px -10px;
}
.footer-link li {
    position: relative;
    padding: 4px 10px;
}
.footer-link li::before {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: -2px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #72a5c3;
}
.footer-link li:first-child::before {
    display: none;
}
.footer-link li a {
    display: block;
    font-size: 1.4rem;
    line-height: 1.2;
    color: #03578d;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-link {
        margin: 0 -20px;
    }
    .footer-link li {
        padding: 0 20px;
    }
    .footer-link li a {
        font-size: 1.6rem;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}

.footer-info {
    font-size: 1.4rem;
    margin: -2px -10px;
    overflow: auto;
}
.footer-info::after {
    content: "";
    clear: both;
    display: table;
}
.footer-info li {
    position: relative;
    float: left;
    display: inline-block;
    padding: 2px 10px;
}
.footer-info li > span {
    margin-right: 4px;
}
.footer-info li.clear {
    clear: left;
}
.footer-info li.clear::before {
    display: none;
}
@media (min-width: 576px) {
    .footer-info {
        font-size: 1.6rem;
        margin: -2px -16px;
    }
    .footer-info li {
        padding: 2px 16px;
    }
}
@media (min-width: 768px) {
    .footer-info li::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        display: block;
        width: 1px;
        height: 14px;
        background-color: #4d8cb5;
    }
    .footer-info li:first-child::before {
        display: none;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}

.copyright {
    font-size: 1.2rem;
    color: #959595;
}
.familysite {
    width: 200px;
    font-size: 1.4rem;
    border: 1px solid #fff;
    border-radius: 0;
}
.familysite .v-input__slot {
    height: 36px !important;
}
.familysite .v-text-field--outlined legend {
    border: none;
}
.v-application .familysite,
.familysite input::placeholder,
.familysite .v-select__selection--comma,
.familysite .mdi:before {
    color: #fff !important;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .copyright {
        font-size: 1.4rem;
    }
    .familysite .v-input__slot {
        height: 44px !important;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
