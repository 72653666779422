var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pt-40 pt-md-0 pl-md-20 pl-lg-40 pl-xl-60 pt-lg-50"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "tit-wrap--dot mb-0"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("행사일정")])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-size-14 font-size-md-16 font-size-lg-18 grey-4e--text"
  }, [_vm._v(_vm._s(_vm.date))])]), _c('v-spacer'), _vm.moreBtn ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-small",
    attrs: {
      "rounded": "",
      "color": "primary lighten-5",
      "to": "/program/schedule"
    }
  }, [_c('i', {
    staticClass: "icon icon-plus-sm mr-4"
  }), _vm._v("더보기")])], 1) : _vm._e()], 1), _c('v-divider', {
    staticClass: "my-14 my-md-24"
  }), _c('v-row', {
    staticClass: "row--x-small"
  }, [_vm.dateItems.length < 1 ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "font-size-18 font-size-md-20"
  }, [_vm._v("진행중인 행사 일정이 없습니다.")])]) : _vm._e(), _vm._l(_vm.dateItems, function (item) {
    var _item$category;
    return _c('v-col', {
      key: `${item._id} - ${item.state}`,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      attrs: {
        "to": `/program/items/${item._id}`
      }
    }, [_c('v-row', {
      staticClass: "row--small font-size-18 font-size-md-20 cursor-pointer"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "primary--text"
    }, [_vm._v("•")])]), _c('v-col', [_c('span', {
      staticClass: "primary--text pr-14 pr-md-18"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.state))]), _c('span', [_vm._v("[" + _vm._s(item === null || item === void 0 ? void 0 : (_item$category = item.category) === null || _item$category === void 0 ? void 0 : _item$category.name) + "] " + _vm._s(item === null || item === void 0 ? void 0 : item.name))])])], 1)], 1)], 1);
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }