<template>
    <div class="main-banner__wrap">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(program, index) in programs" :key="program._id + index">
                    <router-link class="main-banner__inner" :to="`/program/items/${program._id}`">
                        <div class="main-banner__img" :style="`background-image:url('${program?.thumb?.url}');`"></div>
                        <div class="main-banner__con">
                            <span class="main-banner__label">{{ program?.category?.name }}</span>
                            <h3 class="main-banner__tit">{{ program?.name }}</h3>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper/bundle";

export default {
    props: {
        programs: { type: Array, default: () => [] },
    },
    data() {
        return {
            swiper: undefined,
        };
    },
    mounted() {
        this.init();
    },
    watch: {
        "programs.length"() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.programs.length) {
                this.$nextTick(() => {
                    this.swiper = new Swiper(".main-banner .swiper-container", {
                        allowTouchMove: true,
                        centeredSlides: true,
                        slidesPerView: 2,
                        spaceBetween: 10,
                        autoplay: {
                            delay: 5000,
                            disableOnInteraction: false,
                        },
                        speed: 500,
                        loop: true,
                        // pagination: {
                        //     el: ".main-banner .swiper-pagination",
                        //     clickable: true,
                        // },
                        navigation: {
                            prevEl: ".main-banner .swiper-prev",
                            nextEl: ".main-banner .swiper-next",
                        },
                        breakpoints: {
                            576: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                            1024: {
                                centeredSlides: false,
                                slidesPerView: 3,
                                spaceBetween: 18,
                            },
                            1200: {
                                centeredSlides: false,
                                slidesPerView: 4,
                                spaceBetween: 18,
                            },
                        },
                    });
                });
            }
        },
    },
};
</script>

<style scoped>
/* main-banner */
.main-banner__wrap {
    position: relative;
}
.main-banner__wrap::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -12px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    display: block;
    width: 100vw;
    height: 140px;
    background-color: var(--v-primary-lighten5);
}
.main-banner__inner {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: var(--rounded);
}
.main-banner__img {
    width: 100%;
    padding-top: calc(298 / 210 * 100%);
    border-radius: var(--rounded);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.main-banner__con {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #fff;
    padding: 12px;
    background-image: -webkit-linear-gradient(to top, var(--v-primary-base), transparent);
    background-image: -ms-linear-gradient(to top, var(--v-primary-base), transparent);
    background-image: linear-gradient(to top, var(--v-primary-base), transparent);
}
.main-banner__label {
    display: inline-flex;
    align-items: center;
    height: 22px;
    font-size: 1.4rem;
    color: #fff;
    background-color: var(--v-primary-base);
    border-radius: 6px;
    padding: 0 6px;
    margin-bottom: 8px;
}
.main-banner__tit {
    font-size: 1.6rem;
    font-weight: 700;
    max-height: 3em;
    line-height: 1.5;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-banner__wrap::before {
        left: -30px;
        border-radius: var(--rounded);
    }
    .main-banner__label {
        font-size: 1.6rem;
        padding: 0 8px;
        margin-bottom: 10px;
    }
    .main-banner__tit {
        font-size: 2rem;
    }
    .main-banner__con {
        padding: 20px;
    }
}
@media (min-width: 1024px) {
    .main-banner__inner {
        padding-top: 20px;
        -webkit-transition: all ease-out 0.2s;
        -ms-transition: all ease-out 0.2s;
        transition: all ease-out 0.2s;
    }
    .main-banner__inner:hover {
        transform: translateY(-20px);
    }
    .main-banner__wrap {
        margin-top: -20px;
    }
    .main-banner__wrap::before {
        left: -60px;
    }
}
@media (min-width: 1200px) {
}
</style>
