<template>
    <section class="page-section">
        <v-container v-if="this.$slots['default']">
            <slot />
        </v-container>
        <slot v-if="this.$slots['pageSectionImmersive']" name="pageSectionImmersive" />
    </section>
</template>

<script>
export default{
    props : {
    },
    components: {
    },
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>

<style scoped>
.page-section{
    padding: 60px 0 0;
}
.page-section--py{
    padding: 60px 0;
}
.page-section--small{
    padding: 40px 0 0;
}
.page-section--first{
    padding-top: 60px;
    margin-top: -60px;
}
.page-section--last{
    padding-bottom: 60px;
    margin-bottom: -60px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .page-section{
        padding: 80px 0 0;
    }
    .page-section--py{
        padding: 80px 0;
    }
    .page-section--small{
        padding: 60px 0 0;
    }
    .page-section--first{
        padding-top: 80px;
        margin-top: -80px;
    }
    .page-section--last{
        padding-bottom: 80px;
        margin-bottom: -80px;
    }
}
@media (min-width:1024px){
    .page-section{
        padding: 120px 0 0;
    }
    .page-section--py{
        padding: 120px 0;
    }
    .page-section--small{
        padding: 90px 0 0;
    }
    .page-section--first{
        padding-top: 140px;
        margin-top: -140px;
    }
    .page-section--last{
        padding-bottom: 140px;
        margin-bottom: -140px;
    }
}
@media (min-width:1200px){
}

.page-section--bg{
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
}
</style>
