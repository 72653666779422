<template>
    <div v-if="className == 'sub-tab-wrap'" :class="className">
        <v-container>
            <template v-for="item in gnbs">
                <ul v-if="item.title == tabTitle" :key="item.title" class="tab justify-center">
                    <li v-for="child in item.children" class="tab__li" :class="{ active: tabActive == child.title }" :key="child.title">
                        <router-link class="tab__btn" :to="child.path"
                            ><span>{{ child.title }}</span></router-link
                        >
                    </li>
                </ul>
            </template>
        </v-container>
    </div>

    <ul v-else-if="className == 'gnb'" :class="className">
        <template v-for="item in gnbs">
            <li v-if="!item.children" :key="item?.title" :class="className + '__li'">
                <router-link :to="item.path" :class="className + '__link'">
                    <h2>{{ item.title }}</h2>
                </router-link>
            </li>

            <li v-else :key="item?.title" :class="className + '__li'">
                <div :class="className + '__link gnb__link--toggle d-xl-none'">
                    <h2>{{ item.title }}</h2>
                </div>
                <router-link :to="item.path" :class="className + '__link gnb__link--toggle d-none d-xl-flex'">
                    <h2>{{ item.title }}</h2>
                </router-link>
                <ul :class="className + '__sub'">
                    <li v-for="child in item.children" :key="child?.title">
                        <router-link :to="child.path">{{ child.title }}</router-link>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    props: {
        className: String,
        indexNum: String,
        tabTitle: { type: String, default: "" },
        tabActive: { type: String, default: "" },
    },
    data: function() {
        return {
            window: {
                width: 0,
                height: 0,
            },
            gnbs: [
                {
                    title: "센터소개",
                    path: "/intro/center",
                    children: [
                        {
                            title: "센터소개",
                            path: "/intro/center",
                        },
                        {
                            title: "CI/BI",
                            path: "/intro/ci",
                        },
                        {
                            title: "오시는길",
                            path: "/intro/location",
                        },
                    ],
                },
                {
                    title: "참여프로그램",
                    path: "/program",
                    children: [
                        {
                            title: "전체일정",
                            path: "/program/schedule",
                        },
                        {
                            title: "전체프로그램",
                            path: "/program/items",
                        },
                    ],
                },
                {
                    title: "공간대여",
                    path: "/rental",
                    children: [
                        {
                            title: "시설소개",
                            path: "/rental/spaces",
                        },
                        {
                            title: "예약안내",
                            path: "/rental/intro",
                        },
                        {
                            title: "예약하기",
                            path: "/rental/action",
                        },
                    ],
                },
                {
                    title: "주민지원사업",
                    path: "/business/resident",
                    children: [
                        {
                            title: "주민공모사업",
                            path: "/business/resident",
                        },
                        {
                            title: "녹색건축물 조성지원사업",
                            path: "/business/green",
                        },
                        {
                            title: "노후주택 개보수지원사업",
                            path: "/business/house",
                        },
                    ],
                },
                {
                    title: "하남시도시재생",
                    path: "/regeneration/what",
                    children: [
                        {
                            title: "도시재생이란?",
                            path: "/regeneration/what",
                        },
                        {
                            title: "사업구성",
                            path: "/regeneration/structure",
                        },
                        {
                            title: "신장동 뉴딜사업",
                            path: "/regeneration/newdeal",
                        },
                    ],
                },
                {
                    title: "커뮤니티",
                    path: "/center/notice",
                    children: [
                        {
                            title: "공지사항",
                            path: "/center/notice",
                        },
                        {
                            title: "도시재생 소식",
                            path: "/board/press",
                        },
                        {
                            title: "아카이브",
                            path: "/board/archive",
                        },
                    ],
                },
            ],
        };
    },
    computed: {
        isDesktop: function() {
            return this.window.width >= 1200;
        },
        isMobile: function() {
            return this.window.width < 1200;
        },
    },
    created: function() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted: function() {},
    methods: {
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
};
</script>

<style scoped></style>
