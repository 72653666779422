var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--x-small font-size-14 font-size-md-16 line-height-1",
    attrs: {
      "justify": "end",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('gnb-login-button')], 1), !_vm.accessToken ? _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-10px",
    attrs: {
      "vertical": ""
    }
  })], 1) : _vm._e(), !_vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "grey-8f--text",
    attrs: {
      "to": "/join"
    }
  }, [_c('span', [_vm._v("회원가입")])])], 1) : _vm._e(), _vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-menu', {
    attrs: {
      "offset-y": "",
      "z-index": "100",
      "content-class": "v-menu--default min-w-md-150px mt-10 mt-md-20"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "px-14 px-md-18",
          attrs: {
            "rounded": "",
            "height": "36",
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_c('span', {
          staticClass: "font-size-16 font-size-md-20"
        }, [_vm._v("마이페이지")])])];
      }
    }], null, false, 1828594741)
  }, [_c('div', {
    staticClass: "white pa-10 px-md-10 py-md-24 text-center font-size-14 font-size-md-16"
  }, [_c('ul', [_c('li', {
    staticClass: "v-menu--default__txt"
  }, [_c('router-link', {
    staticClass: "d-block",
    attrs: {
      "to": "/mypage/dashboard"
    }
  }, [_vm._v("대시보드")])], 1), _c('li', {
    staticClass: "v-menu--default__txt"
  }, [_c('router-link', {
    staticClass: "d-block",
    attrs: {
      "to": "/mypage/program-applications"
    }
  }, [_vm._v("프로그램")])], 1), _c('li', {
    staticClass: "v-menu--default__txt"
  }, [_c('router-link', {
    staticClass: "d-block",
    attrs: {
      "to": "/mypage/rental-schedules"
    }
  }, [_vm._v("대관예약")])], 1), _c('li', {
    staticClass: "v-menu--default__txt"
  }, [_c('router-link', {
    staticClass: "d-block",
    attrs: {
      "to": "/mypage/question"
    }
  }, [_vm._v("1:1문의")])], 1), _c('li', {
    staticClass: "v-menu--default__txt"
  }, [_c('router-link', {
    staticClass: "d-block",
    attrs: {
      "to": "/mypage/myinfo"
    }
  }, [_vm._v("내정보")])], 1)])])])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }