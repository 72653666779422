import axios from "@/plugins/axios";

let url = (data) => "/api/console/programs/${_program}/attendances".interpolate(data);

export default {
    gets(data) {
        return axios.get(url(data), data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url(data)}/${data._id}`).then((result) => result.data);
    },
    post(data) {
        return axios.post(url(data), data).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url(data)}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url(data)}/${data._id}`).then((result) => result.data);
    },
};
