import Vue from "vue";
import VueRouter from "vue-router";
import axios from "../plugins/axios";
import { i18n } from "../plugins/vue-i18n";

import Main from "../pages/client/Main.vue";
import { BoardRoutes } from "./board.router";
import { QuestionRoutes } from "./question.router";

Vue.use(VueRouter);

const routes = [
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },
    {
        path: "/common/kcp/cert/request",
        component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
        props: true,
    },
    {
        path: "/common/kcp/cert/response",
        component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
        props: true,
    },
    {
        // 가이드
        path: "/guide",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/Guide.vue"),
        props: true,
    },
    {
        // 로그인
        path: "/login",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
        props: true,
    },
    {
        // 비밀번호 찾기
        path: "/login/find-password",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-password.vue"),
        props: true,
    },
    {
        // 아이디 찾기
        path: "/login/find-id",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-id.vue"),
        props: true,
    },
    {
        // 회원가입
        path: "/join",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/main.vue"),
        props: true,
    },
    {
        // 회원가입 폼
        path: "/join/type",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/type.vue"),
        props: true,
    },
    {
        // 회원가입 폼
        path: "/join/form",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/form.vue"),
        props: true,
    },
    {
        // 회원가입 완료
        path: "/join/complete",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/complete.vue"),
        props: true,
    },
    {
        // 마이페이지 - 대시보드
        path: "/mypage",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/Dashboard.vue"),
        props: true,
    },
    {
        // 마이페이지 - 대시보드
        path: "/mypage/dashboard",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/Dashboard.vue"),
        props: true,
    },
    {
        // 마이페이지 - 내정보
        path: "/mypage/myinfo",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
        props: true,
    },
    {
        // 마이페이지 - 내정보
        path: "/mypage/myinfo/:tab",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
        props: true,
    },
    {
        // 마이페이지 - 회원탈퇴
        path: "/mypage/leave",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
        props: true,
    },
    {
        // 마이페이지 - 회원탈퇴
        path: "/mypage/leave/:tab",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
        props: true,
    },
    {
        // 마이페이지 - 1:1문의
        path: "/mypage/question",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/question.vue"),
        children: [...QuestionRoutes],
        props: true,
    },
    {
        // 마이페이지 - 프로그램
        path: "/mypage/program-applications",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ProgramApplicationList.vue"),
        props: true,
    },
    {
        // 마이페이지 - 프로그램 - 출석확인
        path: "/mypage/programs/:_program",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ProgramView.vue"),
        props: true,
    },
    {
        // 마이페이지 - 프로그램
        path: "/mypage/rental-schedules",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/RentalScheduleList.vue"),
        props: true,
    },
    {
        // 개인정보처리방침
        path: "/privacy-policy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/PrivacyPolicyPage.vue"),
        props: true,
    },
    {
        // 서비스이용약관
        path: "/service-policy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/ServicePolicyPage.vue"),
        props: true,
    },
    {
        // 도시재생지원센터 - 도시재생지원센터
        path: "/intro/center",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/intro/CenterPage.vue"),
        props: true,
    },
    {
        // 도시재생지원센터 - CI
        path: "/intro/ci",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/intro/CiPage.vue"),
        props: true,
    },
    {
        // 도시재생지원센터 - 오시는길
        path: "/intro/location",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/intro/LocationPage.vue"),
        props: true,
    },
    {
        // 주민지원사업 - 주민공모사업
        path: "/business/resident",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/business/ResidentPage.vue"),
        props: true,
    },
    {
        // 주민지원사업 - 녹색건축물 조성지원사업
        path: "/business/green",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/business/GreenPage.vue"),
        props: true,
    },
    {
        // 주민지원사업 - 노후주택 개보수지원사업
        path: "/business/house",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/business/HousePage.vue"),
        props: true,
    },
    {
        // 하남시도시재생 - 도시재생이란
        path: "/regeneration/what",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/regeneration/WhatPage.vue"),
        props: true,
    },
    {
        // 하남시도시재생 - 사업구성
        path: "/regeneration/structure",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/regeneration/StructurePage.vue"),
        props: true,
    },
    {
        // 하남시도시재생 - 신장동 뉴딜사업
        path: "/regeneration/newdeal",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/regeneration/NewdealPage.vue"),
        props: true,
    },
    {
        // 커뮤니티 - 공지사항
        path: "/center/notice",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },
    {
        // 커뮤니티 - 공지사항
        path: "/center/notice/:_notification",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },
    {
        // 커뮤니티 - 보도자료
        path: "/board/press",
        component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Press.vue"),
        children: [...BoardRoutes],
        props: true,
    },
    {
        // 커뮤니티 - 아카이브
        path: "/board/archive",
        component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Archive.vue"),
        children: [...BoardRoutes],
        props: true,
    },

    {
        // 문의 및 제휴
        path: "/form/inquiry-partner",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/InquiryPartner.vue"),
        props: true,
    },
    {
        // 공간대여
        path: "/rental",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/rental/RentalSpaceList.vue"),
        props: true,
    },
    {
        // 공간대여 - 시설소개
        path: "/rental/spaces",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/rental/RentalSpaceList.vue"),
        props: true,
    },
    {
        // 공간대여 - 예약안내
        path: "/rental/intro",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/sub/rental/RentalIntroPage.vue"),
        props: true,
    },
    {
        // 공간대여 - 시설소개
        path: "/rental/action",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/rental/RentalActionPage.vue"),
        props: true,
    },
    {
        // 참여 프로그램
        path: "/program",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/program/ProgramSchedule.vue"),
        props: true,
    },
    {
        // 참여 프로그램
        path: "/program/schedule",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/program/ProgramSchedule.vue"),
        props: true,
    },
    {
        // 참여 프로그램
        path: "/program/items",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/program/ProgramList.vue"),
        props: true,
    },
    {
        // 프로그램 - 상세페이지
        path: "/program/items/:_program",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/program/ProgramView.vue"),
        props: true,
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 대시보드
        path: "/console/dashboard",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/dashboard/DashboardPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormList.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms/:_form",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormView.vue"),
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/levels",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/LevelList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/withdrawals",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/WithdrawalList.vue"),
        props: true,
    },
    {
        // 관리자 - 관리자 목록
        path: "/console/admins",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/AdminList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 목록
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원 생성
        path: "/console/users/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 회원 상세
        path: "/console/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 메인배너
        path: "/console/main-banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/MainBannerPage.vue"),
        props: true,
    },
    {
        // 관리자 - 대여공간 목록
        path: "/console/rental-spaces",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/rental/RentalSpaceList.vue"),
        props: true,
    },
    {
        // 관리자 - 대여예약 관리
        path: "/console/rental-schedules",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/rental/RentalScheduleList.vue"),
        props: true,
    },
    {
        // 관리자 - 프로그램 목록
        path: "/console/programs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/program/ProgramList.vue"),
        props: true,
    },
    {
        // 관리자 - 프로그램 상세
        path: "/console/programs/:_program",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/program/ProgramView.vue"),
        props: true,
    },
    {
        // 관리자 - 프로그램 상세
        path: "/console/programs/:_program/:tab",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/program/ProgramView.vue"),
        props: true,
    },
    {
        // 관리자 - 프로그램 상세
        path: "/console/programs/:_program/:tab/:_id",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/program/ProgramView.vue"),
        props: true,
    },
    {
        // 관리자 - 프로그램 카테고리 목록
        path: "/console/program-categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/program/ProgramCategoryList.vue"),
        props: true,
    },
    {
        // 관리자 - 프로그램 키워드 목록
        path: "/console/program-keywords",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/program/ProgramKeywordList.vue"),
        props: true,
    },
    {
        // 관리자 - 통계 - 프로그램 이용현황
        path: "/console/statistics/program-user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/statistics/ProgramUserStatistics.vue"),
        props: true,
    },
    {
        // 관리자 - 공지사항
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Notification.vue"),
        props: true,
    },
    {
        // 관리자 - FAQ
        path: "/console/center/faqs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Faq.vue"),
        props: true,
    },
    {
        // 관리자 - 1:1문의
        path: "/console/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Question.vue"),
        props: true,
    },
    {
        // 관리자 - 서비스 이용약관
        path: "/console/terms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/terms/TermsList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 팝업
        path: "/console/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popup/PopupList.vue"),
        props: true,
    },
    // {
    //     // 관리자 - 설정
    //     path: "/console/setting",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SettingView.vue"),
    //     props: true,
    // },
    {
        // 관리자 - 설정
        path: "/console/smtp",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SmtpView.vue"),
        props: true,
    },
    {
        // 관리자 - 비밀번호
        path: "/console/password",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/PasswordView.vue"),
        props: true,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("accessToken") ? `Bearer ${sessionStorage.getItem("accessToken")}` : "";
    axios.defaults.headers.common["Accept-Language"] = i18n.locale;

    next();
});

export default router;
