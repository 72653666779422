<template>
    <div class="main-visual">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <!-- <div class="swiper-slide">
                    <div class="main-visual__inner">
                        <div class="main-visual__img" style="background-image:url(/images/main/mainvisual.jpg);"></div>
                        <div class="main-visual__con">
                            <div class="main-visual__icon" style="background-image:url(/images/main/symbol-sleep.png);"></div>
                            <h3 class="main-visual__tit">하남시<br/>도시재생지원센터</h3>
                            <p class="main-visual__txt">
                                주민과 행정의 상호연결으로 도시재생사업을 활성화하며 원활하게 지원합니다
                            </p>
                        </div>
                    </div>
                </div>

                <div class="swiper-slide">
                    <div class="main-visual__inner">
                        <div class="main-visual__img" style="background-image:url(/images/main/mainvisual2.jpg);"></div>
                        <div class="main-visual__con">
                            <div class="main-visual__icon" style="background-image:url(/images/main/symbol-wink.png);"></div>
                            <h3 class="main-visual__tit primary--text">하남시<br/>도시재생지원센터</h3>
                            <p class="main-visual__txt grey-4e--text">
                                주민과 행정의 상호연결으로 도시재생사업을 활성화하며 원활하게 지원합니다
                            </p>
                        </div>
                    </div>
                </div> -->

                <div v-for="(slide, index) in banner?.slides || []" :key="index" class="swiper-slide">
                    <div class="main-visual__inner">
                        <div class="main-visual__img" :style="'background-image:url('+ slide?.image +');'"></div>
                        <div class="main-visual__con">
                            <h3 class="main-visual__tit">{{ slide?.meta?.title }}</h3>
                            <div class="main-visual__info">
                                <ul>
                                    <li>{{ slide?.meta?.location }}</li>
                                    <li>{{ slide?.meta?.date?.toDate() }}</li>
                                </ul>
                            </div>
                            <v-btn v-if="slide?.url" :to="slide?.isOuterLink ? undefined : slide?.url" :href="slide?.isOuterLink ? slide?.url : undefined" :target="slide?.isOuterLink ? '_blank': ''" small text color="white" class="main-visual__btn pa-4 ml-n4">VIEW MORE <i class="icon icon-bar white mt-2 ml-6"></i></v-btn>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-control">
                <div class="swiper-prev"><i class="icon icon-chevron-left-white"></i></div>
                <div class="swiper-next"><i class="icon icon-chevron-right-white"></i></div>
                <!-- <div class="swiper-pagination"></div> -->
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import Swiper from "swiper/bundle";
import detect from "@/plugins/detect";
export default {
    props: {
        pc: Boolean,
        mobile: Boolean,
    },
    data() {
        return {
            detect,
            banner: null,
            swiper: undefined,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { banner } = await api.v1.banners.get({ code: this.$attrs.code });
            this.banner = banner;

            this.$nextTick(() => {
                this.swiper = new Swiper(".main-visual .swiper-container", {
                    effect: "fade",
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 0,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    // pagination: {
                    //     el: ".main-visual .swiper-pagination",
                    //     type: "custom",
                    //     renderCustom: function(swiper, current, total) {
                    //         function numberAppend(n) {
                    //             return n < 10 ? "0" + n.toString() : n.toString();
                    //         }
                    //         return '<span class="swiper-pagination-current">' + numberAppend(current) + '</span> / <span class="swiper-pagination-total">' + numberAppend(total) + "</span>";
                    //     },
                    // },
                    navigation: {
                        nextEl: ".main-visual .swiper-next",
                        prevEl: ".main-visual .swiper-prev",
                    },
                });
            });
        },
        dynamicComponent(url) {
            return (url || "").indexOf("/") == 0 ? "router-link" : "a";
        },
    },
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual {
    position: relative;
    width: 100%;
}
.main-visual__inner {
    position: relative;
}
.main-visual__img {
    width:100%;
    padding-top: calc(2/3 * 100%);
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
}
.main-visual__con {
    position: absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); transform: translate(-50%,-50%);
    width:100%;
    max-width: var(--container);
    color: #fff;
    padding:0 12px;
}
.main-visual__icon{
    width:50px;
    height: 40px;
    background-repeat: no-repeat;
    background-position:center;
    background-size: contain;
    margin-bottom: 6px;
}
.main-visual__tit {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
}
.main-visual__tit + .main-visual__info {
    margin-top: 14px;
}
.main-visual__txt{
    font-size: 1.6rem;
    margin-top: 10px;
}
.main-visual__info > ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -4px -8px;
}
.main-visual__info > ul > li {
    position: relative;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1;
    padding: 4px 8px;
}
.main-visual__info + .main-visual__btn {
    margin-top: 20px;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual__img {
        padding-top: calc(714/1920 * 100%);
    }
    .main-visual__icon{
        width:66px;
        height: 52px;
        background-size: auto;
        margin-bottom: 10px;
    }
    .main-visual__tit {
        font-size: 4rem;
    }
    .main-visual__info > ul > li::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translate(0, calc(-50% + 1px));
        -ms-transform: translate(0, calc(-50% + 1px));
        transform: translate(0, calc(-50% + 1px));
        display: block;
        width: 1px;
        height: 10px;
        background-color: #fff;
    }
    .main-visual__info > ul > li:first-child::before {
        display: none;
    }
}
@media (min-width: 1024px) {
    .main-visual__img {
        height: 714px;
        padding-top: 0;
    }
}
@media (min-width: 1200px) {
    .main-visual__tit {
        font-size: 5.6rem;
    }
    .main-visual__tit + .main-visual__info {
        margin-top: 26px;
    }
    .main-visual__txt{
        font-size: 2rem;
        margin-top: 20px;
    }
    .main-visual__info > ul {
        margin: -6px -12px;
    }
    .main-visual__info > ul > li {
        font-size: 2rem;
        padding: 6px 12px;
    }
    .main-visual__info + .main-visual__btn {
        margin-top: 56px;
    }
}
@media (min-width: 1380px) {
    .main-visual__con {
        right: 100px;
    }
}

// main-visual swiper
.main-visual .swiper-container{
    height: 100%;
}
.main-visual .swiper-slide{
    background-color: #f1f1f1;
}
.main-visual .swiper-control{
    top: 10px;
    right: 10px;
    left:auto;
    transform: none;
    width:auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding:0;
}
.main-visual .swiper-pagination{
    position: static;
    bottom:auto;
    left:auto;
    transform: none;
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    width:auto;
    font-size: 1.6rem;
    color:var(--v-primary-base);
    margin:0 6px;
}
.main-visual .swiper-pagination .swiper-pagination-current,
.main-visual .swiper-pagination .swiper-pagination-total{
    display: inline-block;
}
.main-visual .swiper-pagination-current{
    color:#fff;
    padding-right: 4px;
}
.main-visual .swiper-pagination-total{
    padding-left: 4px;
}
.main-visual .swiper-prev,
.main-visual .swiper-next{
    position: sticky;
    left:auto;
    right:auto;
    transform: none;
    background-color: rgba(255,255,255,.35);
}
.main-visual .swiper-prev{
    margin-right: 6px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .main-visual .swiper-pagination{
        font-size: 3rem;
        margin:0 16px;
    }
    .main-visual .swiper-pagination-current{
        padding-right: 8px;
    }
    .main-visual .swiper-pagination-total{
        padding-left: 8px;
    }
}
@media (min-width:1380px){
    .main-visual .swiper-control{
        top: 50%;
        left:50%;
        right:auto;
        -webkit-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); transform: translate(-50%,-50%);
        width: 100%;
        justify-content: space-between;
        padding:0 12px;
    }
    .main-visual .swiper-prev{
        margin-right: 0;
    }
}
</style>
