import "@mdi/font/css/materialdesignicons.css"
import Vue from "vue";
import Vuetify from "vuetify";

// Translation provided by Vuetify (javascript)
import { ko } from "vuetify/src/locale";

// VCurrencyField
import VCurrencyField from "v-currency-field";
Vue.use(VCurrencyField, {
    locale: "kr-KR",
    decimalLength: 0,
    autoDecimalMode: false,
    defaultValue: 0,
    min: null,
    max: null,
    valueAsInteger: false,
    allowNegative: true,
});

Vue.use(Vuetify);

export const basicTheme = {
    themes: {
        light: {
            primary: {
                base: "#008ee8",
                lighten4: "#e2f3fa",
                lighten5: "#eef8fc",
            },
            secondary: "#dadada",
            accent: "#82B1FF",
            error: '#FF5252',
            info: "#2196F3",
            success: '#4CAF50',
            warning: '#FFC107',
            anchor: "inherit",
            pink: {
                lighten1: "#eb3564",
            },
            green: {
                lighten1: "#609800",
            },
            yellow: {
                darken3: "#f9be00",
            },
            "grey-1e": "#1e1e1e",
            "grey-4e": "#4e4e4e",
            "grey-8f": "#8f8f8f",
            "grey-d6": "#d6e1e4",
        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    },
    options: { customProperties: true },
};

export const consoleTheme = {
    themes: {
        light: {
            primary: "#00C73C",
            secondary: "#445163",
            accent: "#00C73C",
            // error: '#FF5252',
            info: "#2D3539",
            content: "#EDF0F5",
            // success: '#4CAF50',
            // warning: '#FFC107',
            anchor: "#333",
        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    },
};

/**
 *
 * @param {(basicTheme|consoleTheme)} theme
 * @returns
 */
export default function(theme) {
    return new Vuetify({
        lang: {
            locales: { ko },
            current: "ko",
        },
        breakpoint: {
            thresholds: {
                xs: 576,
                sm: 768,
                md: 1024,
                lg: 1200,
            },
        },
        theme: theme || basicTheme,
    });
}
