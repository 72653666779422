var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "absolute",
      "top": "0",
      "left": "0",
      "width": "0",
      "height": "0",
      "z-index": "999"
    }
  }, _vm._l(_vm.popups, function (popup) {
    return _c('transition', {
      key: popup._id
    }, [_c(_vm.skinComponent, {
      tag: "component",
      attrs: {
        "popup": popup
      }
    })], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }