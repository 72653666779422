var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-banner__wrap"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.programs, function (program, index) {
    var _program$thumb, _program$category;
    return _c('div', {
      key: program._id + index,
      staticClass: "swiper-slide"
    }, [_c('router-link', {
      staticClass: "main-banner__inner",
      attrs: {
        "to": `/program/items/${program._id}`
      }
    }, [_c('div', {
      staticClass: "main-banner__img",
      style: `background-image:url('${program === null || program === void 0 ? void 0 : (_program$thumb = program.thumb) === null || _program$thumb === void 0 ? void 0 : _program$thumb.url}');`
    }), _c('div', {
      staticClass: "main-banner__con"
    }, [_c('span', {
      staticClass: "main-banner__label"
    }, [_vm._v(_vm._s(program === null || program === void 0 ? void 0 : (_program$category = program.category) === null || _program$category === void 0 ? void 0 : _program$category.name))]), _c('h3', {
      staticClass: "main-banner__tit"
    }, [_vm._v(_vm._s(program === null || program === void 0 ? void 0 : program.name))])])])], 1);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }