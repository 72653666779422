<template>
    <page-section class="page-section--py">
        <div class="main-banner">
            <v-row>
                <v-col cols="12" md="3">
                    <div class="tit-wrap">
                        <h2 class="tit tit--lg">센터운영 <br class="d-none d-md-block" />프로그램</h2>
                    </div>
                    <v-btn rounded color="primary lighten-5" class="v-size--xx-small" to="/program/items"><i class="icon icon-plus-sm mr-4"></i>더보기</v-btn>

                    <div class="mt-40 mt-md-60">
                        <div class="swiper-control">
                            <div class="swiper-prev"><i class="icon icon-chevron-left"></i></div>
                            <div class="swiper-next"><i class="icon icon-chevron-right"></i></div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="9">
                    <main-program-banners v-bind="{ programs }" />
                </v-col>
            </v-row>
        </div>
    </page-section>
</template>

<script>
import api from "@/api";
import PageSection from "../dumb/page-section.vue";
import MainProgramBanners from "./main-program-banners.vue";
export default {
    components: {
        PageSection,
        MainProgramBanners,
    },
    data: () => ({
        limit: 12,
        programs: [],
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { limit } = this;
            let { programs } = await api.v1.programs.gets({
                headers: { limit },
                params: { status: "activated" },
            });
            this.programs = programs;
        },
    },
};
</script>

<style></style>
