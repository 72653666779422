var render = function render(){
  var _vm$user, _vm$user2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('main-header'), _c('v-main', {
    attrs: {
      "id": "main"
    }
  }, [_vm._t("subHead"), _vm._t("contentsImmersive"), _c('div', {
    attrs: {
      "id": "contents"
    }
  }, [_vm._t("default")], 2)], 2), _c('main-footer', {
    staticClass: "pb-40 pb-md-50 pb-lg-0"
  }), _c('v-card', {
    staticClass: "quick-menu"
  }, [_c('ul', [_c('li', [_c('a', {
    staticClass: "quick-menu__link",
    attrs: {
      "target": "_blank",
      "href": "https://instagram.com/hanamhada"
    }
  }, [_c('div', {
    staticClass: "quick-menu__icon icon-insta"
  }), _c('div', {
    staticClass: "quick-menu__tit"
  }, [_vm._v("하다 인스타")])])]), _c('li', [_c('a', {
    staticClass: "quick-menu__link",
    attrs: {
      "target": "_blank",
      "href": "https://cafe.naver.com/hanamregen"
    }
  }, [_c('div', {
    staticClass: "quick-menu__icon icon-cafe"
  }), _c('div', {
    staticClass: "quick-menu__tit"
  }, [_vm._v("카페")])])]), _vm.user ? _c('li', [_c('v-dialog-default', {
    attrs: {
      "title": "내 카드"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('a', _vm._g(_vm._b({
          staticClass: "quick-menu__link"
        }, 'a', attrs, false), on), [_c('div', {
          staticClass: "quick-menu__icon icon-qr"
        }), _c('div', {
          staticClass: "quick-menu__tit"
        }, [_vm._v("내카드")])])];
      }
    }], null, false, 1235715253),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('div', {
    staticClass: "pa-20 py-md-30 pt-lg-54 pb-lg-34 px-lg-64"
  }, [_c('div', {
    staticClass: "img-wrap"
  }, [_c('qrcode-vue', {
    staticClass: "d-flex justify-center pa-4",
    attrs: {
      "value": (_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.number,
      "size": 178
    }
  })], 1), _c('v-divider', {
    staticClass: "border-2 primary lighten-4 my-20 my-md-40"
  }), _c('v-img', {
    attrs: {
      "max-width": "144",
      "src": "/images/logo.svg"
    }
  }), _c('div', {
    staticClass: "font-size-16 font-size-md-20 mt-10 mt-md-14"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "sm": "3"
    }
  }, [_c('b', {
    staticClass: "pr-10"
  }, [_vm._v("이름")])]), _c('v-col', [_vm._v(_vm._s(_vm.user.name))])], 1), _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "sm": "3"
    }
  }, [_c('b', {
    staticClass: "pr-10"
  }, [_vm._v("회원번호")])]), _c('v-col', [_vm._v(_vm._s(_vm.user.number))])], 1)], 1)], 1)])], 1) : _vm._e()])]), _vm.user ? _c('div', {
    staticClass: "mycard-mobile d-lg-none"
  }, [_c('div', {
    staticClass: "mycard-mobile__open",
    on: {
      "click": function ($event) {
        _vm.sheet = !_vm.sheet;
      }
    }
  }, [_c('span', {
    staticClass: "font-size-16 font-size-md-18 font-weight-bold"
  }, [_vm._v("내 카드 "), _c('v-icon', {
    staticClass: "ml-4 mb-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-chevron-up")])], 1)]), _c('v-bottom-sheet', {
    attrs: {
      "content-class": "rounded-t overflow-hidden"
    },
    model: {
      value: _vm.sheet,
      callback: function ($$v) {
        _vm.sheet = $$v;
      },
      expression: "sheet"
    }
  }, [_c('div', {
    staticClass: "white pa-20 py-md-30"
  }, [_c('div', {
    staticClass: "mycard-mobile__header"
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-bold white--text"
  }, [_vm._v("내 카드")]), _c('v-btn', {
    staticClass: "mycard-mobile__close",
    attrs: {
      "icon": "",
      "color": "white"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.sheet = !_vm.sheet;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "pt-30"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "img-wrap"
  }, [_c('qrcode-vue', {
    staticClass: "d-flex justify-center ma-4",
    attrs: {
      "value": (_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.number,
      "size": 180
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider', {
    staticClass: "border-2 primary lighten-4 my-6"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mb-16"
  }, [_c('v-img', {
    attrs: {
      "max-width": "144",
      "src": "/images/logo.svg"
    }
  })], 1), _c('div', {
    staticClass: "font-size-16 font-size-md-20"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "sm": "3"
    }
  }, [_c('b', {
    staticClass: "pr-10"
  }, [_vm._v("이름")])]), _c('v-col', [_vm._v(_vm._s(_vm.user.name))])], 1), _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "sm": "3"
    }
  }, [_c('b', {
    staticClass: "pr-10"
  }, [_vm._v("회원번호")])]), _c('v-col', [_vm._v(_vm._s(_vm.user.number))])], 1)], 1)])], 1)], 1)])])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }