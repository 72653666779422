import axios from "@/plugins/axios";

let url = (data) => "/api/v1/me/rental-schedules/".interpolate(data);

export default {
    gets(data) {
        return axios.get(url(data), data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url(data)}`).then((result) => result.data);
    },
    post(data) {
        return axios.post(url(data), data).then((result) => result.data);
    },
    putCancel(data) {
        return axios.put(`${url(data)}/${data._id}/cancel`, data).then((result) => result.data);
    },
};
