<template>
    <v-col cols="12" md="6" class="py-20 px-md-20 px-lg-40">
        <div class="mb-14 mb-md-24">
            <v-row align="center" justify="space-between">
                <v-col cols="auto">
                    <div class="tit-wrap--dot mb-0"><h2 class="tit">도시재생소식</h2></div>
                </v-col>
                <v-col cols="auto">
                    <v-btn text color="primary" class="v-size--xx-small pa-0" to="/board/press"><i class="icon icon-plus-sm mr-4"></i>더보기</v-btn>
                </v-col>
            </v-row>
        </div>

        <v-row>
            <v-col cols="12" md="6" v-for="item in boards" :key="item._id">
                <router-link :to="`/board/press/${item._id}`">
                    <div class="main-board__tit font-size-18 font-size-md-20 font-weight-semibold">{{ item.subject }}</div>
                    <v-divider class="my-10 my-md-30" />
                    <div class="font-size-14 font-size-md-16 grey-8f--text">{{ item.createdAt?.toDate?.() }}</div>
                </router-link>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import api from "@/api";
export default {
    data: () => ({
        boards: [],
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { boards } = await api.v1.boards.gets({ headers: { limit: 2 }, params: { code: "press" } });
            this.boards = boards;
        },
    },
};
</script>

<style></style>
