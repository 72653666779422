<template>
    <v-row justify="end" align="center" class="row--x-small font-size-14 font-size-md-16 line-height-1">
        <v-col cols="auto">
            <gnb-login-button></gnb-login-button>
        </v-col>
        <v-col v-if="!accessToken" cols="auto" class="d-flex align-center">
            <v-divider vertical class="h-10px" />
        </v-col>
        <v-col v-if="!accessToken" cols="auto">
            <router-link to="/join" class="grey-8f--text">
                <span>회원가입</span>
            </router-link>
        </v-col>
        <v-col v-if="accessToken" cols="auto">
            <v-menu offset-y z-index="100" content-class="v-menu--default min-w-md-150px mt-10 mt-md-20">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" rounded height="36" color="primary" class="px-14 px-md-18">
                        <span class="font-size-16 font-size-md-20">마이페이지</span>
                    </v-btn>
                </template>
                <div class="white pa-10 px-md-10 py-md-24 text-center font-size-14 font-size-md-16">
                    <ul>
                        <li class="v-menu--default__txt"><router-link class="d-block" to="/mypage/dashboard">대시보드</router-link></li>
                        <li class="v-menu--default__txt"><router-link class="d-block" to="/mypage/program-applications">프로그램</router-link></li>
                        <li class="v-menu--default__txt"><router-link class="d-block" to="/mypage/rental-schedules">대관예약</router-link></li>
                        <li class="v-menu--default__txt"><router-link class="d-block" to="/mypage/question">1:1문의</router-link></li>
                        <li class="v-menu--default__txt"><router-link class="d-block" to="/mypage/myinfo">내정보</router-link></li>
                    </ul>
                </div>
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
import gnbLoginButton from "@/components/client/login/gnb-login-button.vue";

export default {
    components: {
        gnbLoginButton,
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>