<template>
    <v-dialog v-model="dialog" max-width="500" content-class="rounded">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <v-card class="v-card--dialog">
            <v-toolbar tile color="primary">
                <v-toolbar-title class="font-size-16 font-size-lg-20 font-weight-bold text-center white--text">{{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon color="white" @click.stop="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <slot />
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, required: true, },
        title: { type:String },
    },
    data: () => ({
    }),
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    mounted() {
    },
    methods: {
    },
};
</script>

<style scoped>
>>>.v-card--dialog .v-toolbar{
    height: 40px !important;
    box-shadow: none !important;
}
>>>.v-card--dialog .v-toolbar__content{
    height: 100% !important;
    padding: 0;
}
>>>.v-card--dialog .v-toolbar__title{
    width: 100%;
    margin-right:-40px;
    padding: 0 40px;
}
>>>.v-card--dialog .v-toolbar__content .v-btn.v-btn--icon{
    width: 40px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    >>>.v-card--dialog .v-toolbar{
        height: 55px !important;
    }
    >>>.v-card--dialog .v-toolbar__title{
        margin-right:-55px;
        padding: 0 55px;
    }
    >>>.v-card--dialog .v-toolbar__content .v-btn.v-btn--icon{
        width: 55px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>
