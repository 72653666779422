var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    staticClass: "py-20 px-md-20 px-lg-40",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "mb-14 mb-md-24"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "tit-wrap--dot mb-0"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("공지사항")])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-small pa-0",
    attrs: {
      "text": "",
      "color": "primary",
      "to": "/center/notice"
    }
  }, [_c('i', {
    staticClass: "icon icon-plus-sm mr-4"
  }), _vm._v("더보기")])], 1)], 1)], 1), _c('v-row', _vm._l(_vm.notifications, function (item) {
    var _item$createdAt, _item$createdAt$toDat;
    return _c('v-col', {
      key: item._id,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('router-link', {
      attrs: {
        "to": `/center/notice/${item._id}`
      }
    }, [_c('div', {
      staticClass: "main-board__tit font-size-18 font-size-md-20 font-weight-semibold"
    }, [_vm._v(_vm._s(item.subject))]), _c('v-divider', {
      staticClass: "my-10 my-md-30"
    }), _c('div', {
      staticClass: "font-size-14 font-size-md-16 grey-8f--text"
    }, [_vm._v(_vm._s((_item$createdAt = item.createdAt) === null || _item$createdAt === void 0 ? void 0 : (_item$createdAt$toDat = _item$createdAt.toDate) === null || _item$createdAt$toDat === void 0 ? void 0 : _item$createdAt$toDat.call(_item$createdAt)))])], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }