<template>
    <client-page class="app--main">
        <!-- S: 메인 비주얼 -->
        <main-visual code="main-banners-pc" class="d-none d-md-block" />
        <main-visual code="main-banners-mobile" class="d-md-none" />
        <!-- E: 메인 비주얼 -->

        <!-- S: 간편메뉴 -->
        <v-container>
            <v-card rounded="xl" class="h-lg-200px mt-40 mt-sm-n40 mt-md-40 mt-lg-n100 d-flex align-center" style="z-index:1;">
                <div class="w-100 px-20 py-30 px-lg-74 py-lg-36">
                    <v-row align="center" justify="space-between">
                        <v-col cols="12" md="auto">
                            <div class="tit-wrap mb-6 mb-md-10"><h2 class="tit">간편메뉴</h2></div>
                            <p class="page-text">클릭으로 빠르게 원하시는 정보를 찾아보세요.</p>
                        </v-col>
                        <v-col cols="12" md="" lg="auto">
                            <v-row align="center" class="ma-n8 ma-md-n12">
                                <v-col cols="4" lg="auto" class="pa-8 pa-md-12 text-center">
                                    <router-link to="/program/items">
                                        <v-responsive width="100px" :aspect-ratio="1 / 1" class="ma-auto">
                                            <v-sheet width="100%" height="100%" rounded="circle" color="primary lighten-5" class="d-flex align-center justify-center"><i class="icon icon-calendar"></i></v-sheet>
                                        </v-responsive>
                                        <p class="page-text font-size-16 mt-8 word-keep-all">운영 프로그램</p>
                                    </router-link>
                                </v-col>
                                <v-col cols="4" lg="auto" class="pa-8 pa-md-12 text-center">
                                    <router-link to="/rental/spaces">
                                        <v-responsive width="100px" :aspect-ratio="1 / 1" class="ma-auto">
                                            <v-sheet width="100%" height="100%" rounded="circle" color="primary lighten-5" class="d-flex align-center justify-center"><i class="icon icon-place"></i></v-sheet>
                                        </v-responsive>
                                        <p class="page-text font-size-16 mt-8">공간대관</p>
                                    </router-link>
                                </v-col>
                                <v-col cols="4" lg="auto" class="pa-8 pa-md-12 text-center">
                                    <router-link to="/intro/location">
                                        <v-responsive width="100px" :aspect-ratio="1 / 1" class="ma-auto">
                                            <v-sheet width="100%" height="100%" rounded="circle" color="primary lighten-5" class="d-flex align-center justify-center"><i class="icon icon-car"></i></v-sheet>
                                        </v-responsive>
                                        <p class="page-text font-size-16 mt-8">오시는길</p>
                                    </router-link>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-container>
        <!-- E: 간편메뉴 -->

        <!-- S: 센터운영프로그램 -->
        <main-programs />
        <!-- E: 센터운영프로그램 -->

        <!-- S: 게시판 -->
        <page-section class="page-section--py page-section--bg" style="background-image:url(/images/main/hura-bg.png); background-position:center bottom;">
            <v-row class="main-board my-n20 mx-md-n20 mx-lg-n40">
                <!-- S: 공지사항 -->
                <main-notices />
                <!-- E: 공지사항 -->
                <!-- S: 보도자료 -->
                <main-presses />
                <!-- E: 보도자료 -->
            </v-row>
        </page-section>
        <!-- E: 게시판 -->

        <!-- S: 일정 -->
        <page-section class="page-section--py">
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <v-card>
                        <div class="pa-20 pa-md-30">
                            <program-schedule-calendar v-bind="{ programs }" no-title />
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <program-schedule-list v-bind="{ programs }" moreBtn />
                </v-col>
            </v-row>
        </page-section>
        <!-- E: 일정 -->

        <!-- <v-row justify="center">
            <v-col cols="12" align="center" class="py-0">
                <span class="subtitle-1 font-weight-bold">다국어 샘플</span> <br>
                <span class="caption">(해당 내용은 테스트하고 지우면되요)</span>
            </v-col>
            <v-col cols="auto" class="py-0">
                <locale-select label="언어변경"></locale-select>
            </v-col>
            <v-col cols="12" align="center">
                <div> 한국어 번역 경로: src/locales/ko.js </div>
                <div> 영어 번역 경로: src/locales/en.js </div>
                <div> 중국어 번역 경로: src/locales/cn.js </div>
                <div class="mt-4"> 사용법 $t() 함수 안에, src/locales/{locale}.js 에서 작성한 json 키워드 나열 </div>
                <div class="font-weight-bold mt-4">JSON 작성법1</div>
                <div>
                    {
                        "key1": "value1"
                    }
                </div>
                <div class="font-weight-bold mt-4">JSON 작성법2</div>
                <div>
                    {
                        "key1": {
                            "key2": "value2"
                        }
                    }
                </div>
                <div class="font-weight-bold mt-4">JSON 작성법3 - 우리 회사 룰 대략 아래처럼 정하죠 </div>
                <div>
                    {
                        page: {
                            section1: "내용1",
                            section2: {
                                title: "타이틀",
                                content: "내용"
                            }
                        }
                    }
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example1</div>
                    <div class="body-1">$t("common.로그인")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("common.로그인") }}</div>
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example2</div>
                    <div class="body-1">$t("main.section1")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section1") }}</div>
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example3</div>
                    <div class="body-1">$t("main.section2.title")</div>
                    <div class="body-1">$t("main.section2.content")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section2.title") }}</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section2.content") }}</div>
                </div>
            </v-col>
        </v-row> -->
        <popup-layer></popup-layer>
    </client-page>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";

// import BoardList from "@/components/client/board/board-list.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MainVisual from "@/components/client/main/main-visual.vue";
import PageSection from "@/components/client/dumb/page-section.vue";
import MainPrograms from "@/components/client/main/main-programs.vue";
import ProgramScheduleList from "@/components/client/program/program-schedule-list.vue";
import ProgramScheduleCalendar from "@/components/client/program/program-schedule-calendar.vue";
import MainNotices from "@/components/client/main/main-notices.vue";
import MainPresses from "@/components/client/main/main-presses.vue";

export default {
    components: {
        // BoardList,
        PopupLayer,
        ClientPage,
        MainVisual,
        PageSection,
        MainPrograms,
        ProgramScheduleList,
        ProgramScheduleCalendar,
        MainNotices,
        MainPresses,
    },
    props: {
        isMain: { type: Boolean, default: true },
    },
    data() {
        return {
            date: this.$route.query.date || Date.now().toDate(),
            programs: [],

            limit: 10,
            summary: { totalCount: 0 },
        };
    },
    computed: {
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        headers() {
            let { limit } = this;
            return { skip: (this.page - 1) * limit, limit };
        },
        params() {
            return {
                dateFrom: dayjs(this.date)
                    .startOf("month")
                    .toISOString(),
                dateTo: dayjs(this.date)
                    .endOf("month")
                    .toISOString(),
            };
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        "$route.query.date"(date = Date.now().toDate()) {
            this.date = date;
        },
        params() {
            this.search();
        },
    },
    methods: {
        async search() {
            let { headers, params } = this;
            let { summary, programs } = await api.v1.programs.gets({ headers, params });
            this.summary = summary;
            this.programs = programs;
        },
    },
};
</script>

<style scoped>
.main-board__tit {
    display: block;
    width: 100%;
    height: 2.8em;
    line-height: 1.4;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
